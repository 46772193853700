const modelName = 'App Services'
const modelNameSingular = 'App Service'
const endpoint = '/app-services'

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true,
  endpoint,
  accessor: 'id'
}

export default setup
