/* eslint-disable */
const links = [
  {
    group: "Messages",
    items: [
      {
        title: "Announcements",
        href: "/announcements",
        permissions: ['Super Admin'],
      },
      {
        title: "Messages",
        href: "/messages",
        permissions: ['Super Admin'],
      },
    ],
    permissions: ['Super Admin'],
  },
  {
    group: "Customers",
    link: "/customers",
    items: [],
    permissions: ['Super Admin', 'RDA ALL'],
  },
  {
    group: "Products",
    items: [
      {
        title: "Products",
        href: "/products",
        permissions: ['Super Admin'],
      },
      {
        title: "Image upload",
        href: "/product-image",
        permissions: ['Super Admin'],
      },
    ],
    permissions: ['Super Admin'],
  },
  {
    group: "Brands",
    link: "/brands",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "PDF Links",
    link: "/pdf-links",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "Categories",
    link: "/categories",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "Promoted Categories",

    items: [
      {
        title: "Promoted Categories",
        href: "/promoted-categories",
        permissions: ['Super Admin'],
      },
    ],
    permissions: ['Super Admin'],
  },
  {
    group: "Banners",
    link: "/banners",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "Tags",
    link: "/tags",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "AB Testing",
    items: [
      {
        title: "Product",
        href: "/ab-product",
        permissions: ['Super Admin'],
      },
      {
        title: "Product Impressions",
        href: "/product-impressions",
        permissions: ['Super Admin'],
      },
      {
        title: "Banner",
        href: "/ab-banner",
        permissions: ['Super Admin'],
      },
      {
        title: "Banner Impressions",
        href: "/banner-impressions",
        permissions: ['Super Admin'],
      },
    ],
    permissions: ['Super Admin'],
  },
  {
    group: "Minimum Order",
    items: [
      {
        title: "Minimum Quantities",
        href: "/minimum-quantities",
        permissions: ['Super Admin', 'RDA'],
      },
      {
        title: "Minimum Quantities Customers",
        href: "/minimum-quantities-customers",
        permissions: ['Super Admin', 'RDA'],
      },
    ],
    permissions: ['Super Admin', 'RDA'],
  },
  {
    group: "App Versions",
    link: "/app-versions",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "App Services",
    link: "/app-services",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "App Events",
    items: [
      {
        title: 'Suppliers',
        href: '/event-suppliers',
        permissions: ['Super Admin'],
      },
      {
        title: 'Events',
        href: '/app-events',
        permissions: ['Super Admin'],
      },
      {
        title: 'Event Suppliers',
        href: '/app-event-suppliers',
        permissions: ['Super Admin'],
      },
      {
        title: 'Event Trolleys',
        href: '/app-event-trolleys',
        permissions: ['Super Admin'],
      },
      {
        title: 'Event Impressions',
        href: '/app-event-impressions',
        permissions: ['Super Admin'],
      }
    ],
    permissions: ['Super Admin'],
  },
  {
    group: "Testimonials",
    link: "/testimonials",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "Own Label Range",
    link: "/own-label-range",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "Promotions",
    items: [
      {
        title: "Promotions",
        href: "/price-promotions",
        permissions: ['Super Admin'],
      },
      {
        title: "Super Promotions",
        href: "/super-promotions",
        permissions: ['Super Admin'],
      },
      {
        title: "Interstitial Ads",
        href: "/interstitial-ads",
        permissions: ['Super Admin'],
      },
      {
        title: "Interstitial Ad Impressions",
        href: "/interstitial-ads/impressions",
        permissions: ['Super Admin'],
      },
    ],
    permissions: ['Super Admin'],
  },
  {
    group: 'Promotion Calendar',
    link: '/promotion-calendar',
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "Planograms",
    link: "/planograms",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "Stock",
    link: "/stock-quantity",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "Supplier Logs",
    link: "/supplier-logs",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "Homepage",
    items: [
      {
        title: "Brands",
        href: "/homepage-brands",
        permissions: ['Super Admin'],
      },
      {
        title: "Products",
        href: "/homepage-products",
        permissions: ['Super Admin'],
      },
      {
        title: "Categories",
        href: "/homepage-categories/edit/9",
        permissions: ['Super Admin'],
      },
    ],
    permissions: ['Super Admin'],
  },
  {
    group: "Purchase Limits",
    link: "/purchase-limits",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: 'QR Codes',
    link: '/qr-codes',
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "Pages",
    link: "/pages",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "Resource Categories",
    link: "/resource-categories",
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: "Settings",
    items: [
      {
        title: "General Settings",
        href: "/settings/edit/1",
        permissions: ['Super Admin'],
      },
      {
        title: "Thresholds",
        href: "stock-thresholds",
        permissions: ['Super Admin'],
      },
      {
        title: 'App Branch Contact Details',
        href: '/app-branch-contact-details',
        permissions: ['Super Admin'],
      }
    ],
    permissions: ['Super Admin'],
  },
];

export default links;
