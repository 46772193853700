import React from 'react';
import { EditView, Field } from "@builtbypixel/nucleus";

import setup from './setup';
import { Container, Flex, Text, Grid, GridItem } from "@chakra-ui/react";


const EditForm = () => {

  return (
    <EditView setup={setup} isFullWidth={true}>
      <Container
        p={{ base: "10px", sm: "15px" }}
        w='100%'
        variant='card'
        rounded='none'
        maxWidth='none'
      >
        <Flex direction='column' width="full" gap="4">
          <Field name={'name'} label={'Name'} rules={{ required: true }} component="text" />
          <Field name={'description'} label={'Secondary Text'} rules={{ required: true }} component="textarea" />
          <Field name={'icon'} label={'Icon'} rules={{ required: true }} component="file" />
          <Field name={'image'} label={'Content'}  component="file" />
          <Field name={'url'} label={'Link'} component="text" help='Please only provide a link if you are not uploading an image' />
          <Field name={'active'} label={'Active'} component="switch" />
        </Flex>
      </Container>
    </EditView>
  );
};

export default EditForm;
